import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientesAddComponent } from './components/clientes-add/clientes-add.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DepuracionComponent } from './components/depuracion/depuracion.component';
import { ProyectosAddComponent } from './components/proyectos-add/proyectos-add.component';
import { ProyectosComponent } from './components/proyectos/proyectos.component';
import { RiegoAutomaticoComponent } from './components/riego-automatico/riego-automatico.component';
import { RiegoManualComponent } from './components/riego-manual/riego-manual.component';
import { RiegoProgramadoComponent } from './components/riego-programado/riego-programado.component';
import { SistemasAddComponent } from './components/sistemas-add/sistemas-add.component';
import { SistemasComponent } from './components/sistemas/sistemas.component';
import { UsuariosAddComponent } from './components/usuarios-add/usuarios-add.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';


const routes: Routes = [
  { path: '', component: DashboardComponent},
  { path: 'riego-manual', component: RiegoManualComponent},
  { path: 'clientes', component: ClientesComponent},
  { path: 'clientes-add/:id', component: ClientesAddComponent},
  { path: 'sistemas', component: SistemasComponent},
  { path: 'sistemas-add/:id', component: SistemasAddComponent},
  { path: 'proyectos', component: ProyectosComponent},
  { path: 'proyectos-add/:id', component: ProyectosAddComponent},
  { path: 'usuarios', component: UsuariosComponent},
  { path: 'usuarios-add/:id', component: UsuariosAddComponent},
  { path: 'depuracion', component: DepuracionComponent},
  { path: 'riego-programado', component: RiegoProgramadoComponent},
  { path: 'riego-automatico', component: RiegoAutomaticoComponent},

  { path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
