import { Component, OnInit } from '@angular/core';
import { GlobalsService } from 'src/app/libs/globals.service';
import { webSocket} from 'rxjs/webSocket';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-depuracion',
  templateUrl: './depuracion.component.html',
  styleUrls: ['./depuracion.component.css']  
})
export class DepuracionComponent implements OnInit {

  url = GlobalsService.socket_server;
  subject = webSocket(this.url);
  listChats: any[] = [];
  conectado: boolean = false;
  _router:Router;

  constructor(router: Router) {
    this._router = router;
   }

  ngOnInit(): void {
    document.getElementById('accordionSidebar')?.classList.remove("toggled");

  }   

  ngOnDestroy()	:void {
    this.subject.unsubscribe();
    this.conectado = false;
  }

  inciarDepuracion() {

    this.subject.subscribe(msg => {

      if(!this.conectado) Swal.fire("¡Conectado con el servidor!");
      //this.listChats.unshift(msg);
      this.listChats.push(msg);
      this.conectado = true;
    },err => {
      Swal.fire(err);
      this.subject.unsubscribe();
      this.conectado = false;
      console.log(err);
    });

    var item = {id: "debug"};
    this.subject.next(item);

  }

  detenenerDepuracion() {
    Swal.fire("¡Desconectado del servidor!");
    this.subject.unsubscribe();
    this.conectado = false;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/depuracion']);
  }
}
