import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalsService } from '../libs/globals.service';

@Injectable({
  providedIn: 'root'
})
export class SectorService {

  server = GlobalsService.server
  url = this.server + 'api/sectores/';

  constructor(private http: HttpClient) { }
  
  getSectores(sistemaID: String): Observable<any> {
    return this.http.get(this.url + sistemaID);
  }
}
