<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Riego Automático</h1>
    </div>
    <div class="row">
        <div class="col-12 col-lg-3">
            <div class="form-group">
                <select class="form-control" #sistema (change)="sistemaOnChange(sistema.value)">
                    <option value="">Seleccione un Sistema de Riego</option>
                    <option value="{{sistema.id}}" *ngFor="let sistema of listSistemas">{{sistema.nombre}}</option>
                </select>                                                                
            </div>            
        </div> 
    </div>
    <div class="row" *ngIf="sistema.value != ''">
        <div class="col-12 col-lg-6">
            <div class="form-check form-check-inline">
                <label class="form-label mt-1" style="font-weight: strong;">Modo Operación: </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="options" id="radioManual" ng-controls="options" [checked]="modoOperacion === 'MAN'" (change)="modoChange('MAN')" value="MAN">
                <label class="form-check-label" for="radioManual">Manual</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="options" id="radioAuto" ng-controls="options" [checked]="modoOperacion === 'AUTO'" (change)="modoChange('AUTO')" value="AUTO">
                <label class="form-check-label" for="radioAuto">Automático</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="options" id="radioSecuencial" ng-controls="options" [checked]="modoOperacion === 'SEC'" (change)="modoChange('SEC')" value="SEC">
                <label class="form-check-label" for="radioSecuencial">Secuencial</label>
            </div>
        </div>      
        <pre>{{modoOperacion}}</pre>
    </div>
    <div class="row mt-2" *ngIf="sistema.value != ''">
        <div class="col-12">
            <div class="card shadow mb-4">
                <div class="card-header border-bottom">
                    <!-- Dashboard card navigation-->
                    <ul class="nav nav-tabs card-header-tabs" id="dashboardNav" role="tablist">
                        <li class="nav-item me-1"><a class="nav-link active" id="overview-pill" href="#overview" data-bs-toggle="tab" role="tab" aria-controls="overview" aria-selected="true">Programa A</a></li>
                        <li class="nav-item"><a class="nav-link" id="programab-pill" href="#programab" data-bs-toggle="tab" role="tab" aria-controls="programab" aria-selected="false">Programa B</a></li>
                        <li class="nav-item"><a class="nav-link" id="programac-pill" href="#programac" data-bs-toggle="tab" role="tab" aria-controls="programac" aria-selected="false">Programa C</a></li>
                        <li class="nav-item"><a class="nav-link" id="programad-pill" href="#programad" data-bs-toggle="tab" role="tab" aria-controls="programad" aria-selected="false">Programa D</a></li>

                    </ul>
                </div>
                <!--div class="card-header py-3 bg-primary">
                    <h6 class="m-0 font-weight-bold text-white">Programación Semanal</h6>
                </div-->
                <div class="card-body">  
                    <div class="row">                        
                        <div class="col-12 col-lg-10">
                            <label><span class="badge badge-primary"> 1. </span> Ingrese una hora de inicio y fin para el sector que desea automatizar.(Desde las 00:00 hasta las 23:59 horas.)</label><br>
                            <label><span class="badge badge-primary"> 2. </span> Active el riego en cualquier día de la semana.</label><br>
                            <label><span class="badge badge-primary"> 3. </span> Active el Fertirriego en cualquier día de la semana e ingrese los minutos de irrigación.</label>
                                
                        </div>
                        <div class="col-12 col-lg-2 mt-2">
                            <button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right"  (click)="programar()">
                                <i class="fas fa-save fa-sm text-white-50"></i> Programar</button>
                        </div>
                    </div>    
                    <div class="tab-content" id="dashboardNavContent">
                        <!--PROGRAMA A-->
                        <div class="tab-pane fade active show" id="overview" role="tabpanel" aria-labelledby="overview-pill">
                                <div class="row mt-2">
                                    <div class="col-12">   
                                        <div class="table-responsive" >
                                            <table class="table table-sm table-bordered">
                                                <thead >
                                                    <tr>
                                                        <th scope="col" rowspan="2" style="width: 200px">Sector</th>
                                                        <th scope="col" colspan="2" class="text-center">Hora</th>
                                                        <th scope="col" colspan="3" class="text-center">Lunes</th>
                                                        <th scope="col" colspan="3" class="text-center">Martes</th>
                                                        <th scope="col" colspan="3" class="text-center">Miércoles</th>
                                                        <th scope="col" colspan="3" class="text-center">Jueves</th>
                                                        <th scope="col" colspan="3" class="text-center">Viernes</th>
                                                        <th scope="col" colspan="3" class="text-center">Sábado</th>
                                                        <th scope="col" colspan="3" class="text-center">Domingo</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Inicio</th>
                                                        <th>Fin</th>
                                                        <th>Riego</th>
                                                        <th>Ferti.</th>
                                                        <th style="width: 80px;">Tiempo</th>
                                                        <th>Riego</th>
                                                        <th>Ferti.</th>
                                                        <th style="width: 80px;">Tiempo</th>
                                                        <th>Riego</th>
                                                        <th>Ferti.</th>
                                                        <th style="width: 80px;">Tiempo</th>
                                                        <th>Riego</th>
                                                        <th>Ferti.</th>
                                                        <th style="width: 80px;">Tiempo</th>
                                                        <th>Riego</th>
                                                        <th>Ferti.</th>
                                                        <th style="width: 80px;">Tiempo</th>
                                                        <th>Riego</th>
                                                        <th>Ferti.</th>
                                                        <th style="width: 80px;">Tiempo</th>
                                                        <th>Riego</th>
                                                        <th>Ferti.</th>
                                                        <th style="width: 80px;">Tiempo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let sector of listProgramaA">
                                                        <td><strong>{{sector.nombre}}</strong></td> 
                                                        <td><input type="time" class="form-control form-control-sm" value="{{sector.inicio}}" (change)="changeHora($event, sector, 'inicio')" >
                                                        <td><input type="time" class="form-control form-control-sm" value="{{sector.fin}}" (change)="changeHora($event, sector, 'fin')" ></td>                                           
                                                        <!--LUNES-->
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [checked]="sector.lu" (change)="changeCheck(sector, 'lu')" >
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [disabled]="!sector.lu" [checked]="sector.luf" (change)="changeCheckFertirriego(sector, 'luf','f1_tiempo')">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control form-control-sm" [disabled]="!sector.luf" value="{{sector.f1_tiempo}}" (keypress)="numberOnly($event)" (keyup)="keyUpInput(sector, 'f1_tiempo', $event)" maxlength="3" >
                                                        </td>
                                                        <!--MARTES-->
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [checked]="sector.ma" (change)="changeCheck(sector, 'ma')" >
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [disabled]="!sector.ma" [checked]="sector.maf" (change)="changeCheckFertirriego(sector, 'maf','f2_tiempo')">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control form-control-sm" [disabled]="!sector.maf" value="{{sector.f2_tiempo}}" (keypress)="numberOnly($event)" (keyup)="keyUpInput(sector, 'f2_tiempo', $event)" maxlength="3" >
                                                        </td>  
                                                        <!--MIERCOLES-->
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [checked]="sector.mi" (change)="changeCheck(sector, 'mi')" >
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [disabled]="!sector.mi" [checked]="sector.mif" (change)="changeCheckFertirriego(sector, 'mif', 'f3_tiempo')">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control form-control-sm" [disabled]="!sector.mif" value="{{sector.f3_tiempo}}" (keypress)="numberOnly($event)" (keyup)="keyUpInput(sector, 'f3_tiempo', $event)" maxlength="3" >
                                                        </td>   
                                                         <!--JUEVES-->
                                                         <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [checked]="sector.ju" (change)="changeCheck(sector, 'ju')" >
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [disabled]="!sector.ju" [checked]="sector.juf" (change)="changeCheckFertirriego(sector, 'juf','f4_tiempo')">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control form-control-sm" [disabled]="!sector.juf" value="{{sector.f4_tiempo}}" (keypress)="numberOnly($event)" (keyup)="keyUpInput(sector, 'f4_tiempo', $event)" maxlength="3" >
                                                        </td>       
                                                         <!--VIERNES-->
                                                         <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [checked]="sector.vi" (change)="changeCheck(sector, 'vi')" >
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [disabled]="!sector.vi" [checked]="sector.vif" (change)="changeCheckFertirriego(sector, 'vif', 'f5_tiempo')">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control form-control-sm" [disabled]="!sector.vif" value="{{sector.f5_tiempo}}" (keypress)="numberOnly($event)" (keyup)="keyUpInput(sector, 'f5_tiempo', $event)" maxlength="3" >
                                                        </td>   
                                                        <!--SABADO-->
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [checked]="sector.sa" (change)="changeCheck(sector, 'sa')" >
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [disabled]="!sector.sa" [checked]="sector.saf" (change)="changeCheckFertirriego(sector, 'saf', 'f6_tiempo')">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control form-control-sm" [disabled]="!sector.saf" value="{{sector.f6_tiempo}}" (keypress)="numberOnly($event)" (keyup)="keyUpInput(sector, 'f6_tiempo', $event)" maxlength="3" >
                                                        </td> 
                                                        <!--DOMINGO-->
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [checked]="sector.do" [checked]="sector.do" (change)="changeCheck(sector, 'do')" >
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check form-switch text-center ml-3">
                                                                <input class="form-check-input" type="checkbox" [checked]="sector.dof" (change)="changeCheckFertirriego(sector, 'dof', 'f7_tiempo')">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control form-control-sm" [disabled]="!sector.dof" value="{{sector.f7_tiempo}}" (keypress)="numberOnly($event)" (keyup)="keyUpInput(sector, 'f7_tiempo', $event)" maxlength="3" >
                                                        </td>                                                                                                                           
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>                        
                                    </div>                        
                                </div>   
                        </div>
                        <!--PROGRAMA B-->
                        <div class="tab-pane fade" id="programab" role="tabpanel" aria-labelledby="programab-pill">
                            <label>B</label>
                        </div>
                         <!--PROGRAMA C-->
                         <div class="tab-pane fade" id="programac" role="tabpanel" aria-labelledby="programac-pill">
                            <label>C</label>
                        </div>
                         <!--PROGRAMA D-->
                         <div class="tab-pane fade" id="programad" role="tabpanel" aria-labelledby="programad-pill">
                            <label>D</label>
                        </div>
                    </div>              
                 
                                   
                </div>
            </div>
        </div>
    </div>    
</div>