import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuarios-add',
  templateUrl: './usuarios-add.component.html',
  styleUrls: ['./usuarios-add.component.css']
})
export class UsuariosAddComponent implements OnInit {

  usuarioForm: FormGroup;
  titulo = 'Nuevo Usuario';
  isAdd = true;
  usuarioID: string | null;
  fieldTextType = false;
  perfiles: any = ['Administrador', 'Jefe de Proyecto', 'Instalador'];

  constructor(private fb: FormBuilder, private router: Router, private aRouter:ActivatedRoute) {
    this.usuarioForm = this.fb.group({
      id: ['', Validators.required],
      nombre: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      telefono: ['', Validators.required],
      perfil: ['', Validators.required],
      pass: ['', Validators.required]
    })

    this.usuarioID = this.aRouter.snapshot.paramMap.get('usuarioID');

    if(this.usuarioID === "" || this.usuarioID == null ) {
      this.isAdd = true;


      Swal.fire({
        title: 'ADD!',
        text: 'Do you want to continue',
        icon: 'error',
        confirmButtonText: 'Cool'
      });
    }
    else {
      this.isAdd = false;

      Swal.fire({
        title: 'EDIT!',
        text: 'Do you want to continue',
        icon: 'error',
        confirmButtonText: 'Cool'
      });
    }
    
  }

  ngOnInit(): void {

  }
  
  changePerfil(e:any) {
    this.usuarioForm.get('perfil')?.setValue(e.target.value, {
      onlySelf: true
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  agregarUsuario() {

    console.log("llega");
    console.log(this.usuarioForm);

    if(this.usuarioForm.invalid){
      Swal.fire({
        title: 'INVALIDO!',
        text: 'Do you want to continue',
        icon: 'error',
        confirmButtonText: 'Cool'
      });
    }

    const USUARIO: Usuario = {
      id: this.usuarioForm.get('id')?.value,
      nombre: this.usuarioForm.get('nombre')?.value,
      email: this.usuarioForm.get('email')?.value,
      telefono: this.usuarioForm.get('telefono')?.value,
      perfil: this.usuarioForm.get('perfil')?.value,
      pass: this.usuarioForm.get('pass')?.value
    }

    if( this.isAdd) {

      

    } else {

    }

  }
}
