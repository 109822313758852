<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Clientes</h1>
        
        <a class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm" [routerLink]="['/clientes-add', '']"><i
                class="fas fa-plus fa-sm text-white-50" ></i> Nuevo Cliente</a>
    </div>
    <div class="card shadow mb-4 border-left-primary">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Todos los clientes</h6>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Rut</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Dirección</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cliente of listClientes">
                            <td>{{cliente.rut}}</td>
                            <td>{{cliente.nombre}}</td>
                            <td>{{cliente.telefono}}</td>
                            <td>{{cliente.email}}</td>
                            <td>{{cliente.direccion}}</td>
                            <td>
                                <i [routerLink]="['/clientes-add', cliente.id]" class="fas fa-edit text-primary"></i>
                                <i (click)="eliminarCliente(cliente.id)" class="fas fa-trash text-secondary"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>