import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-clientes-add',
  templateUrl: './clientes-add.component.html',
  styleUrls: ['./clientes-add.component.css']
})
export class ClientesAddComponent implements OnInit {

  titulo = 'Nuevo Cliente';
  clienteID:string | null;
  clienteForm: FormGroup;
  listEstados: any[] = [
    {id:'1', nombre:'Habilitado'},
    {id:'0', nombre:'Deshabiltado'}
  ];


  constructor(private fb:FormBuilder, private aRoute: ActivatedRoute) {
    this.clienteID = this.aRoute.snapshot.paramMap.get('id');
    this.clienteForm = this.fb.group({
      rut: ['',[Validators.required]],
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      email: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      estado: ['', [Validators.required]]
    });
   }

  ngOnInit(): void {
    this.checkInit()
  }  

  checkInit(){
    if (this.clienteID != null && !this.isEmpty(this.clienteID)) {
      console.log(this.clienteID);
      this.titulo = 'Editando Cliente';
      /*
      this._productoService.obtenerProducto(this.id).subscribe(data => {
        this.productoForm.setValue({
          producto: data.nombre,
          categoria: data.categoria,
          ubicacion: data.ubicacion,
          precio: data.precio
        })
      })
      */
    }
  }

  agregarCliente() {

  }

  isEmpty(str:string) {
    return (!str || str.length === 0 );
  } 

}
