<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Proyectos</h1>
        
        <a class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm" [routerLink]="['/proyectos-add', '']"><i
                class="fas fa-plus fa-sm text-white-50" ></i> Nuevo proyecto</a>
    </div>
    <div class="card shadow mb-4 border-left-primary">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Todos los proyectos</h6>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Codigo</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Cliente Rut</th>
                            <th scope="col">Cliente Nombre</th>
                            <th scope="col">Registro</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let proyecto of listProyectos">
                            <td>{{proyecto.id}}</td>
                            <td>{{proyecto.nombre}}</td>
                            <td>{{proyecto.clienteRut}}</td>
                            <td>{{proyecto.clienteNombre}}</td>
                            <td>{{proyecto.registro_fecha | date:'dd/MM/yyyy HH:mm' }}</td>
                            <td>
                                <i [routerLink]="['/proyectos-add', proyecto.id]" class="fas fa-edit text-primary"></i>
                                <i (click)="eliminarProyecto(proyecto.id)" class="fas fa-trash text-secondary"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>