

    <!-- Main Content -->
    <div class="content" id="content">

       <app-nav-bar></app-nav-bar>
       <router-outlet></router-outlet>
       

    </div>
    <!-- End of Main Content -->


    <!-- Footer -->
    <footer class="sticky-footer bg-white">
        <div class="container my-auto">
            <div class="copyright text-center">
                <span>Desarrollado por</span>       
            </div>    
        </div>
        <div class="row justify-content-md-center">
            <div class="col-lg-2 col-xs-6">
                <img class="brand" src="assets/img/trion.png"  alt="trion">
            </div>
            <div class="col-lg-2">
                <img class="brand" src="assets/img/riegochile_color.png"  alt="riegochile">
            </div>
            <div class="col-lg-2">      
                <img class="brand" src="assets/img/udec.png" alt="udec">         
            </div>
            <div class="col-lg-2">
                <img class="brand" src="assets/img/corfo.png" alt="corfo">  
            </div>
        </div>        
    </footer>
    <!-- End of Footer -->

<!-- End of Content Wrapper -->