import {  formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { Programa } from 'src/app/models/programa';
import { RiegoProgramadoService } from 'src/app/services/riego-programado.service';
import { SectorService } from 'src/app/services/sector.service';
import { SistemaService } from 'src/app/services/sistema.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-riego-programado',
  templateUrl: './riego-programado.component.html',
  styleUrls: ['./riego-programado.component.css']
})
export class RiegoProgramadoComponent implements OnInit {

  tolerancia:number = 33.33;
  horaInicio:string = "";
  sectorID = "";
  listSectores: any[] = [];
  listSistemas: any[] = [];
  listBombas: any[] = [];
  listProgramas: any[] = [];  
  listDias:any[] = [];
  programaForm: FormGroup;
  fertirriegoForm: FormGroup;
  
  constructor(private fb: FormBuilder,private _riegoProgramadoService:RiegoProgramadoService,
    private _sistemaService:SistemaService, private _sectorService:SectorService) {     

      this.programaForm = this.fb.group({
        sectorID: ['', Validators.required],
        diaID: ['', Validators.required],
        inicio: ['', Validators.required],
        fin: ['', Validators.required]
      });

      this.fertirriegoForm = this.fb.group({    
        inicio: ['', Validators.required],
        fin: ['', Validators.required]               
      });

      this.listDias.push({diaID:1, nombre:'Lunes'});
      this.listDias.push({diaID:2, nombre:'Martes'});
      this.listDias.push({diaID:3, nombre:'Miércoles'});
      this.listDias.push({diaID:4, nombre:'Jueves'});
      this.listDias.push({diaID:5, nombre:'Viernes'});
      this.listDias.push({diaID:6, nombre:'Sábado'});
      this.listDias.push({diaID:7, nombre:'Domingo'});

      this.listProgramas.push({ dia:"Lunes", inicio:"07:00", fin:"09:00", rele:"Relé N° 1", tipo:"Bomba", sector:"Sector 1"});
      this.listProgramas.push({ dia:"Lunes", inicio:"07:30", fin:"08:30", rele:"Relé N° 2", tipo:"Bomba Fertirriego", sector:"Sector 1"});
      this.listProgramas.push({ dia:"Lunes", inicio:"07:00", fin:"09:00", rele:"Relé N° 5", tipo:"Sector", sector:"Sector 1"});

      this.listProgramas.push({ dia:"Martes", inicio:"07:45", fin:"09:00", rele:"Relé N° 1", tipo:"Bomba", sector:"Sector 1"});
      this.listProgramas.push({ dia:"Martes", inicio:"07:45", fin:"09:00", rele:"Relé N° 1", tipo:"Bomba", sector:"Sector 1"});
      this.listProgramas.push({ dia:"Martes", inicio:"07:45", fin:"09:00", rele:"Relé N° 1", tipo:"Bomba", sector:"Sector 1"});

     }

  ngOnInit(): void {
    this.obtenerSistemas();
  }

  obtenerSistemas() {
    this._sistemaService.getSistemas().subscribe(data => {
      this.listSistemas = data.data;
    }, error => {
      console.log(error);
    });
  }

  sistemaOnChange(value:any) {   
    this.sectorID = value;
    this.horaInicio = "";
    if(value != ""){
       this.obtenerSectores(value);
       this.obtenerBombas(value);
    }    
  }

  obtenerSectores(sectorID: string) {
    this._sectorService.getSectores(sectorID).subscribe(data => {
      this.listSectores = data.data;
    }, error => {
      console.log(error);
    });
  }

  obtenerBombas(sectorID: string) {
    this._riegoProgramadoService.getBombas(sectorID).subscribe(data => {
      this.listBombas = data.data;
      console.log(this.listBombas);
      if(this.listBombas.length > 0){
     
      }
    }, error => {
      console.log(error);
    });
  }

  obtenerProgramas(sectorID: string) {
    this._riegoProgramadoService.getBombas(sectorID).subscribe(data => {
      this.listBombas = data.data;
      console.log(this.listBombas);
      if(this.listBombas.length > 0){
     
      }
    }, error => {
      console.log(error);
    });
  }

  guardar(){
      
  }

  openModal() {

  }


  changeHoraInicio(event:any):void {

    this.horaInicio = event.target.value;
  }

  changeCheck(sector:any, key:any):void{
    sector[key] = !sector[key];
  } 

  changeCheckFertirriego(sector:any, key:any, keyTiempo:any):void {
    
    sector[key] = !this.toBoolean(sector[key]);
    if(!sector[key]) sector[keyTiempo] = 0;

  }

  keyUpInput(sector:any, key:any, event:any):void {
    sector[key] = event.target.value;
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  
 

  programar() {   


    if(this.sectorID == "") Swal.fire('¡Error!', 'Debe seleccionar Sistema de Riego' ,'error');
    else if(this.horaInicio == "" || this.horaInicio == undefined) Swal.fire('¡Error!', 'Debe seleccionar una hora de inicio' ,'error');
    else if(this.listSectores.length == 0) Swal.fire('¡Error!', 'No hay reles configurados' ,'error');
    else {

      var pass:boolean = true;
      var result = new Date("1999-01-01 " + this.horaInicio);
      console.log(this.horaInicio);
      console.log(result);

      var minutos = result.getHours() * 60 + result.getMinutes();
      var total:number = 0;

      console.log("minutos:" + minutos);
      this.listSectores.forEach(sector => {
        
        let tiempo:number = parseInt(sector.tiempo);
        let tiempo_f1:number = parseInt(sector.f1_tiempo);
        let tiempo_f2:number = parseInt(sector.f2_tiempo);
        let tiempo_f3:number = parseInt(sector.f3_tiempo);
        let tiempo_f4:number = parseInt(sector.f4_tiempo);
        let tiempo_f5:number = parseInt(sector.f5_tiempo);
        let tiempo_f6:number = parseInt(sector.f6_tiempo);
        let tiempo_f7:number = parseInt(sector.f7_tiempo);

        let luf:boolean = this.toBoolean(sector.luf);
        let maf:boolean = this.toBoolean(sector.maf);
        let mif:boolean = this.toBoolean(sector.mif);
        let juf:boolean = this.toBoolean(sector.juf);
        let vif:boolean = this.toBoolean(sector.vif);
        let saf:boolean = this.toBoolean(sector.saf);
        let dof:boolean = this.toBoolean(sector.dof);

        if(luf && !this.checkTolerancia(tiempo, tiempo_f1, sector.nombre, 'Lunes')) pass = false;        
        else if(maf && !this.checkTolerancia(tiempo, tiempo_f2, sector.nombre, 'Martes')) pass = false;   
        else if(mif && !this.checkTolerancia(tiempo, tiempo_f3, sector.nombre, 'Miércoles')) pass = false;        
        else if(juf && !this.checkTolerancia(tiempo, tiempo_f4, sector.nombre, 'Jueves')) pass = false;       
        else if(vif && !this.checkTolerancia(tiempo, tiempo_f5, sector.nombre, 'Viernes')) pass = false;     
        else if(saf && !this.checkTolerancia(tiempo, tiempo_f6, sector.nombre, 'Sábado')) pass = false;  
        else if(dof && !this.checkTolerancia(tiempo, tiempo_f7, sector.nombre, 'Domingo')) pass = false;  

        total+= tiempo;
       
      });

      if(pass && total <= 0) {
        console.log("total:" + total);
        Swal.fire('Atención', '¡El tiempo de riego no puede ejecutarse en 0 minutos!', 'error');
        pass = false;
      }

      minutos += total;

      if(pass && minutos >=1439) {
        console.log("minutos2:" + minutos);
        Swal.fire('Atención', '¡El tiempo de riego no puede superar las 24 horas!', 'error');
        pass = false;
      }

      if(pass){       

        var insert:number = 1;

        this.listSectores.forEach(item => {

          const PROGRAMA: Programa = {
            id: item.programaID,
            rele: item.releID,
            inicio: this.horaInicio,
            tiempo: item.tiempo,
            lu: this.toInt(item.lu).toString(),
            luf: this.toInt(item.luf).toString(),
            f1_tiempo: item.f1_tiempo,
            ma: this.toInt(item.ma).toString(),
            maf: this.toInt(item.maf).toString(),
            f2_tiempo: item.f2_tiempo,
            mi: this.toInt(item.mi).toString(),
            mif: this.toInt(item.mif).toString(),
            f3_tiempo: item.f3_tiempo,
            ju: this.toInt(item.ju).toString(),
            juf: this.toInt(item.juf).toString(),
            f4_tiempo: item.f4_tiempo,
            vi: this.toInt(item.vi).toString(),
            vif: this.toInt(item.vif).toString(),
            f5_tiempo: item.f5_tiempo,
            sa: this.toInt(item.sa).toString(),
            saf: this.toInt(item.saf).toString(),
            f6_tiempo: item.f6_tiempo,
            do: this.toInt(item.do).toString(),
            dof: this.toInt(item.dof).toString(),
            f7_tiempo: item.f7_tiempo,
            fin: "",
            programa: "" 
          }

          this._riegoProgramadoService.updatePrograma(PROGRAMA).subscribe(data=> {

            try {
              
              let result = data.data[0].result;
              
              if(result == "UPDATE") {            
                insert++;
                if(insert == this.listSectores.length)  Swal.fire('Exito', '¡Riego Programado Correctamente!', 'success');
              }
              else {
                Swal.fire('¡Ocurrio un error al editar el Sistema de Riego!', '¡Error!', 'error');
              }
    
            } catch (error) {
              //console.log(error);
               Swal.fire('¡Error!', data ,'error');
            }

          }, error => {
            console.log(error);
            Swal.fire('¡Error!', error.message,'error');
            //this.sistemaForm.reset();
            //this.hardwareForm.reset();
          });

        });
        
      }

    }
  } 

  checkTolerancia(tiempo:number, tiempo_f:number, nombre:string, dia:string):boolean {

      if(tiempo_f == 0 || isNaN(tiempo_f)) {
        Swal.fire('Error en ' + nombre + ' día ' + dia, 'El tiempo de Fertirriego no puede ser 0' ,'error');
        return false;
      }

      if(tiempo == 0 || isNaN(tiempo)) {
        Swal.fire('Error en ' + nombre + ' día ' + dia, 'El tiempo de Riego no puede ser 0' ,'error');
        return false;
      }

      let per:number = (tiempo_f * 100) / tiempo;
      let percentaje:number = parseFloat(per.toFixed(2));
      if(percentaje > this.tolerancia) {
        Swal.fire('Error en ' + nombre + ' día ' + dia, 'El tiempo de Fertirriego no puede superar el 33% del tiempo de Riego' ,'error');
        return false;
      }
      else return true;
  }
  
  toBoolean (value:any): boolean {
    switch (value) {
      case 'true':
      case '1':
      case 'on':
      case 'yes':
      case 1:
      case true:
        return true;
      default:
        return false;
    }
  }

  toInt(value:any):number {
    switch (value) {
      case 'true':
      case '1':
      case 'on':
      case 'yes':
      case 1:
      case true:
        return 1;
      default:
        return 0;
    }
  }
}
