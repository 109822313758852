import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalsService } from '../libs/globals.service';
import { Programa } from '../models/programa';

@Injectable({
  providedIn: 'root'
})
export class RiegoAutomaticoService {

  server = GlobalsService.server
  url = this.server + 'api/programa-auto/';

  constructor(private http: HttpClient) { }
  
  getProgramaAuto(sistemaID:string, programa:string): Observable<any> {
    return this.http.post(this.url, {id:sistemaID, programa:programa});
  }

  updatePrograma(programa:Programa): Observable<any> {
    return this.http.post(this.url + 'update/', programa);
  }
  
}
