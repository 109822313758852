import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalsService } from '../libs/globals.service';
import { Sistema } from '../models/sistema';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  
  server = GlobalsService.server;
  url = this.server + 'api/clientes';

  constructor(private http: HttpClient) { }

  getClientes(): Observable<any> {
    return this.http.get(this.url);
  }

  guardarSistema(sistema: Sistema):Observable<any> {
    return this.http.post(this.url, sistema);
  }
}
