<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Depuración</h1>
    </div>
    
    <!-- Content Row -->

    <div class="row justify-content-md-center">

        <!-- Area Chart -->
        <div class="col-12">
            <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Consola</h6>
                    <button class="btn btn-SM btn-success shadow-sm float-right" [hidden]="conectado" (click)="inciarDepuracion()"><i
                            class="fas fa-link fa-sm text-white-50"></i> Conectar</button>
                    <button class="btn btn-SM btn-danger shadow-sm float-right" [hidden]="!conectado" (click)="detenenerDepuracion()"><i
                                class="fas fa-link fa-sm text-white-50"></i> Desconectar</button>
                </div>
                <!-- Card Body -->
                <div class="card-body">
                    <div class="card bg-black text-success mt-1">
                        <div class="scroll" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                            <div *ngFor="let chat of listChats">
                                <div class="text-white small p-1">{{chat.cliente}}</div>
                                <div class="text-green small p-1">{{chat.servidor}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="small">
                            <span class="mr-2" [hidden]="!conectado">
                                <i class="fas fa-circle text-success" ></i> Conectado
                            </span>
                            <span class="mr-2" [hidden]="conectado">
                                <i class="fas fa-circle text-danger"></i> Desconectado
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- /.container-fluid -->