<li class="nav-item">
    <a class="nav-link" [routerLink]="['/']">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></a>
</li>

<!-- Divider -->
<hr class="sidebar-divider">

<!-- Heading -->
<div class="sidebar-heading">
    Operación
</div>

<!-- Nav Item - Riego Manual -->
<li class="nav-item" >
    <a class="nav-link" [routerLink]="['/riego-manual']" >
        <i class="fas fa-toggle-on"></i>
        <span>Riego Manual</span></a>
</li>

<!-- Nav Item - Riego Programado -->
<li class="nav-item" >
    <a class="nav-link" [routerLink]="['/riego-programado']" >
        <i class="far fa-calendar-check"></i>
        <span>Riego Programado</span></a>
</li>


<!-- Divider -->
<hr class="sidebar-divider">

<!-- Heading -->
<div class="sidebar-heading">
    Administrador
</div>

<!-- Nav Item - Depuración -->
<li class="nav-item" >
    <a class="nav-link" [routerLink]="['/depuracion']" >
        <i class="fas fa-terminal"></i>
        <span>Depuración</span></a>
</li>

<!-- Divider -->
<hr class="sidebar-divider">

<!-- Heading -->
<div class="sidebar-heading">
    Sistema
</div>

<!-- Nav Item - Clientes -->
<li class="nav-item" >
    <a class="nav-link" [routerLink]="['/clientes']" >
        <i class="fas fa-user-tie"></i>
        <span>Clientes</span></a>
</li>

<!-- Nav Item - Proyectos -->
<li class="nav-item" >
    <a class="nav-link" [routerLink]="['/proyectos']" >
        <i class="fas fa-address-card"></i>
        <span>Proyectos</span></a>
</li>

<!-- Nav Item - Sistema de Riego -->
<li class="nav-item" >
    <a class="nav-link" [routerLink]="['/sistemas']" >
        <i class="fas fa-clipboard-list"></i>
        <span>Sistemas de Riego</span></a>
</li>

<!-- Nav Item - Sistema de Riego -->
<li class="nav-item" >
    <a class="nav-link" [routerLink]="['/usuarios']" >
        <i class="far fa-user-circle"></i>
        <span>Usuarios</span></a>
</li>

<!-- Divider -->
<hr class="sidebar-divider">

<!--

<div class="sidebar-card">
    <img class="sidebar-card-illustration mb-2" src="assets/img/undraw_rocket.svg" alt="">
    <p class="text-center mb-2"><strong>SB Admin Pro</strong> is packed with premium features, components, and more!</p>
    <a class="btn btn-success btn-sm" href="https://startbootstrap.com/theme/sb-admin-pro">Upgrade to Pro!</a>
</div>





<li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <i class="fas fa-fw fa-wrench"></i>
        <span>Utilities</span>
    </a>
    <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
        data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Custom Utilities:</h6>
            <a class="collapse-item" href="utilities-color.html">Colors</a>
            <a class="collapse-item" href="utilities-border.html">Borders</a>
            <a class="collapse-item" href="utilities-animation.html">Animations</a>
            <a class="collapse-item" href="utilities-other.html">Other</a>
        </div>
    </div>
</li>

<hr class="sidebar-divider">

<div class="sidebar-heading">
    Addons
</div>

<li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
        aria-expanded="true" aria-controls="collapsePages">
        <i class="fas fa-fw fa-folder"></i>
        <span>Pages</span>
    </a>
    <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Login Screens:</h6>
            <a class="collapse-item" href="login.html">Login</a>
            <a class="collapse-item" href="register.html">Register</a>
            <a class="collapse-item" href="forgot-password.html">Forgot Password</a>
            <div class="collapse-divider"></div>
            <h6 class="collapse-header">Other Pages:</h6>
            <a class="collapse-item" href="404.html">404 Page</a>
            <a class="collapse-item" href="blank.html">Blank Page</a>
        </div>
    </div>
</li>

<li class="nav-item">
    <a class="nav-link" href="charts.html">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Charts</span></a>
</li>

<li class="nav-item">
    <a class="nav-link" href="tables.html">
        <i class="fas fa-fw fa-table"></i>
        <span>Tables</span></a>
</li>

<hr class="sidebar-divider d-none d-md-block">

<div class="text-center d-none d-md-inline">
    <button class="rounded-circle border-0" id="sidebarToggle"></button>
</div>

<-->