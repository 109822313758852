import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-riego-secuencial',
  templateUrl: './riego-secuencial.component.html',
  styleUrls: ['./riego-secuencial.component.css']
})
export class RiegoSecuencialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
