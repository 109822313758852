import { Component, OnInit } from '@angular/core';
import { Sector } from 'src/app/models/sector';
import { Sistema } from 'src/app/models/sistema';
import { ManualService } from 'src/app/services/manual.service';
import { SistemaService } from 'src/app/services/sistema.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-riego-manual',
  templateUrl: './riego-manual.component.html',
  styleUrls: ['./riego-manual.component.css']
})
export class RiegoManualComponent implements OnInit {

  manual = true;
  sistemaID = "";
  listSistemas: any[] = [];
  listSectores: any[] = [];
  comunicacion = "";

  constructor(private _sistemaService: SistemaService, private _manualService: ManualService) {
    //document.getElementById('accordionSidebar')?.classList.remove("toggled");
  }

  ngOnInit(): void {

    document.getElementById('accordionSidebar')?.classList.remove("toggled");
    this.obtenerSistemas();

  }

  guardarManual() {

    if (this.sistemaID == "") {
      Swal.fire('¡Debe seleccionar un Sistema de Riego!', '', 'error');
    }
    else {

      const selected = this.listSistemas.find(({ id }) => id == this.sistemaID);
      var sistema: Sistema = selected;

      Swal.fire({
        title: '¿Está a seguro de enviar la configuración al sistema de Riego' + sistema.nombre + '?',
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
         
          if (this.manual) sistema.modo = "MAN";
          else sistema.modo = "AUTO";
          this.updateSistema(this.sistemaID, sistema);
        }
      });

    }
  }

  obtenerSistemas() {
    this._sistemaService.getSistemas().subscribe(data => {
      this.listSistemas = data.data;
    }, error => {
      console.log(error);
    });
  }

  changeCheck(): void {
    this.manual = !this.manual;
  }

  sistemaOnChange(value: any) {

    this.sistemaID = value;
    if (value != "") this.getEstado(value);

  }

  sectorOnChange(sector: any) {

    sector.estado = (sector.estado == 0 ? 1 : 0);
  }

  getEstado(sistemaID: string) {
    this._manualService.getEstado(sistemaID).subscribe(data => {

      if (data.data[0].modo == "AUTO") this.manual = false;
      else this.manual = true;

      this.comunicacion = data.data[0].comunicacion_fecha;
      this.obtenerSectores(this.sistemaID);

    }, error => {
      console.log(error);
    });
  }

  obtenerSectores(sistemaID: string) {
    this._manualService.getSectores(sistemaID).subscribe(data => {
      this.listSectores = data.data;

    }, error => {
      console.log(error);
    });
  }

  updateSistema(sistemaID: string, sistema: Sistema) {
    this._manualService.editSistema(sistemaID, sistema).subscribe(data => {
      try {
        let result = data.data[0].result;
        if (result == "UPDATE") {

          this.listSectores.forEach((sector, index) => {
            this.updateSector(sistemaID, sector);
          });

        }
        else {
          Swal.fire('¡Ocurrio un error al actualizar el Sistema de Riego!', data, 'error');
        }

      } catch (error) {
        Swal.fire('¡Ocurrio un error al actualizar el Sistema de Riego!', data, 'error');
      }

    }, error => {
      console.log(error);
      Swal.fire('¡Error!', error, 'error');
    });
  }

  updateSector(sistemaID: string, sector: Sector) {
    this._manualService.editSector(sistemaID, sector).subscribe(data => {
      try {
        let result = data.data[0].result;
        if (result == "UPDATE") {
         
          let largo = this.listSectores.length - 1;
          let ultimoSector = this.listSectores[largo];

          if(sector.id == ultimoSector.id) {
            Swal.fire('Riego Manual Editado Correctamente!', '', 'success');
          }

        }
        else {
          Swal.fire('¡Ocurrio un error al actualizar el Sector!', data, 'error');
        }

      } catch (error) {
        Swal.fire('¡Ocurrio un error al actualizar el Sector!', data, 'error');
      }

    }, error => {
      console.log(error);
      Swal.fire('¡Error!', error, 'error');
    });
  }

}
