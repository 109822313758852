<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Sistemas de Riego</h1>
        
        <a class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm" [routerLink]="['/sistemas-add', '']"><i
                class="fas fa-plus fa-sm text-white-50" ></i> Nuevo Sistema de Riego</a>
    </div>
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Todos los sistemas de riego</h6>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Controlador ID</th>
                            <th scope="col">Registro</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sistema of listSistemas">
                            <td>{{sistema.nombre}}</td>
                            <td>{{sistema.controladorID}}</td>
                            <td>{{sistema.registro_fecha}}</td>
                            <td>
                                <i [routerLink]="['/sistemas-add', sistema.id]" class="fas fa-edit text-primary"></i>
                                <i (click)="eliminarSistema(sistema.id)" class="fas fa-trash text-secondary"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>