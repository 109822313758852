import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {

  constructor() { }

   //falta un metodo para traer el menu dinamico
   //falta un metodo para hacer el routerlink

  ngOnInit(): void {
  }

  public selected:boolean = true;

  onItemSelected(event: any): void {
   this.selected = !this.selected;
  
  }

}
