import { Component, OnInit } from '@angular/core';
import { SistemaService } from 'src/app/services/sistema.service';

@Component({
  selector: 'app-sistemas',
  templateUrl: './sistemas.component.html',
  styleUrls: ['./sistemas.component.css']
})
export class SistemasComponent implements OnInit {

  listSistemas: any[] = [];

  constructor(private _sistemaService: SistemaService) { }

  ngOnInit(): void {
    document.getElementById('accordionSidebar')?.classList.remove("toggled");

    this.obtenerSistemas();
  }

  obtenerSistemas() {
    this._sistemaService.getSistemas().subscribe(data => {
      this.listSistemas = data.data;
    }, error => {
      console.log(error);
    });
  }

  eliminarSistema(id:any){
    
  }

}
