import { formatDate } from '@angular/common';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Programa } from 'src/app/models/programa';
import { RiegoAutomaticoService } from 'src/app/services/riego-automatico.service';
import { SistemaService } from 'src/app/services/sistema.service';
import Swal from 'sweetalert2';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-riego-automatico',
  templateUrl: './riego-automatico.component.html',
  styleUrls: ['./riego-automatico.component.css']
})
export class RiegoAutomaticoComponent implements OnInit {

  tolerancia:number = 33.33;
  horaInicio:string = "";
  sectorID = "";
  listSectores: any[] = [];
  listSistemas: any[] = [];
  modoOperacion: any;

  listProgramaA:any[] = [];
  listProgramaB:any[] = [];
  listProgramaC:any[] = [];
  listProgramaD:any[] = [];
  
  constructor(private _riegoAutomaticoService:RiegoAutomaticoService,
    private _sistemaService:SistemaService) {     

      document.getElementById('accordionSidebar')?.classList.add("toggled");
     }

  ngOnInit(): void {
    
    this.obtenerSistemas();
  }

  modoChange(value:any):void {
    this.modoOperacion = value;
  }

  obtenerSectores(sectorID: string) {
    this.obtenerPrograma(sectorID, 'A');
    this.obtenerPrograma(sectorID, 'B');
    this.obtenerPrograma(sectorID, 'C');
    this.obtenerPrograma(sectorID, 'D');
  }

  obtenerPrograma(sectorID: string, programa:string) {
    this._riegoAutomaticoService.getProgramaAuto(sectorID, programa).subscribe(data => {

      switch (programa) {
        case 'A':
          this.listProgramaA = data.data;
          console.log(data.data);
          break;
        case 'B':
          this.listProgramaB = data.data;
          break;
        case 'C':
          this.listProgramaC = data.data;
          break;
        case 'D':
          this.listProgramaD = data.data;
          break;      
        default:
          break;
      }

    }, error => {
      console.log(error);
    });
  }

  obtenerSistemas() {
    this._sistemaService.getSistemas().subscribe(data => {
      this.listSistemas = data.data;
      console.log(data.data);
    }, error => {
      console.log(error);
    });
  }

  changeHora(event:any, sector:any, key:any):void{

    sector[key] = event.target.value
    //this.horaInicio = event.target.value;
  }

  changeCheck(sector:any, key:any):void{
    sector[key] = !sector[key];

    if (sector[key] == false) {
      switch (key) {
        case 'lu':
          sector.luf = false;
          sector.f1_tiempo = "";
          break;
        case 'ma':
          sector.maf = false;
          sector.f2_tiempo = "";
          break;
        case 'mi':
          sector.mif = false;
          sector.f3_tiempo = "";
          break;
        case 'ju':
          sector.juf = false;
          sector.f4_tiempo = "";
          break;
        case 'vi':
          sector.vif = false;
          sector.f5_tiempo = "";
          break;
        case 'sa':
          sector.saf = false;
          sector.f6_tiempo = "";
          break;
        case 'do':
          sector.dof = false;
          sector.f7_tiempo = "";
          break;
        default:
          break;
      }
    }    

    if(!sector.lu && !sector.ma && !sector.mi && !sector.ju && !sector.vi && !sector.sa && !sector.do){
      sector.inicio = "";
      sector.fin = "";
    }
  } 

  changeCheckFertirriego(sector:any, key:any, keyTiempo:any):void {
    
    sector[key] = !this.toBoolean(sector[key]);
    if(!sector[key]) sector[keyTiempo] = "";

  }

  keyUpInput(sector:any, key:any, event:any):void {
    sector[key] = event.target.value;
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  
  sistemaOnChange(value:any) {
   
    this.sectorID = value;
    this.horaInicio = "";
    this.modoOperacion = "";

    this.listSistemas.forEach(sistema => {
      
      if(sistema.id == value){
       this.modoOperacion = sistema.modo;
      }
      
    });

    if(value != "") this.obtenerSectores(value);
    
  }

  programar(){

    if(this.validar(this.listProgramaA)) {this.guardarPrograma("A", this.listProgramaA);}
  }

  validar(list:any[]): boolean{

    var pass:boolean = true; 
    var todosNull = true;

    list.forEach(sector => {
      if(sector.inicio != "" || sector.fin != ""){
        todosNull = false;
      }           
    });

    if(todosNull){
      Swal.fire('¡Error!','Debe ingresar al menos una hora de inicio y termino para el programa ' + list[0].programaID);
      return false;
    } 

    list.forEach(sector => {

      var inicio = Date.parse("2000/01/01 " + sector.inicio);//MILISEGUNDOS
      var fin = Date.parse("2000/01/01 " + sector.fin);//MILISEGUNDOS

      if ((sector.inicio == "" && sector.fin != "") || (sector.inicio != "" && sector.fin == "")) {
        Swal.fire('¡Error!', 'Revise el rango de inicio y fin para ' + sector.nombre);
        pass = false;
      }
      else if (inicio >= fin && (sector.inicio != "" || sector.fin != "")) {
        Swal.fire('¡Error!', 'La hora de fin no puede ser menor que la de inicio para ' + sector.nombre);
        pass = false;
      }
      else if ((sector.lu || sector.ma || sector.mi || sector.ju || sector.vi || sector.sa || sector.do)
        && (sector.inicio == "" && sector.fin == "")) {
        Swal.fire('¡Error!', 'Revise el rango de inicio y fin para ' + sector.nombre);
        pass = false;
      }
      else if ((!sector.lu && !sector.ma && !sector.mi && !sector.ju && !sector.vi && !sector.sa && !sector.do)
        && (sector.inicio != "" && sector.fin != "")) {
        Swal.fire('¡Error!', 'Debe activar a lo menos un día para ' + sector.nombre + '<br> o Eliminar la hora de inicio y fin.');
        pass = false;
      }
      else {
        var minutos = ((fin - inicio) /1000) /60;
        sector.tiempo = minutos;

        let tiempo_f1:number = parseInt(sector.f1_tiempo);
        let tiempo_f2:number = parseInt(sector.f2_tiempo);
        let tiempo_f3:number = parseInt(sector.f3_tiempo);
        let tiempo_f4:number = parseInt(sector.f4_tiempo);
        let tiempo_f5:number = parseInt(sector.f5_tiempo);
        let tiempo_f6:number = parseInt(sector.f6_tiempo);
        let tiempo_f7:number = parseInt(sector.f7_tiempo);

        let luf:boolean = this.toBoolean(sector.luf);
        let maf:boolean = this.toBoolean(sector.maf);
        let mif:boolean = this.toBoolean(sector.mif);
        let juf:boolean = this.toBoolean(sector.juf);
        let vif:boolean = this.toBoolean(sector.vif);
        let saf:boolean = this.toBoolean(sector.saf);
        let dof:boolean = this.toBoolean(sector.dof);

        if(luf && !this.checkTolerancia(sector.tiempo, tiempo_f1, sector.nombre, 'Lunes')) pass = false;        
        else if(maf && !this.checkTolerancia(sector.tiempo, tiempo_f2, sector.nombre, 'Martes')) pass = false;   
        else if(mif && !this.checkTolerancia(sector.tiempo, tiempo_f3, sector.nombre, 'Miércoles')) pass = false;        
        else if(juf && !this.checkTolerancia(sector.tiempo, tiempo_f4, sector.nombre, 'Jueves')) pass = false;       
        else if(vif && !this.checkTolerancia(sector.tiempo, tiempo_f5, sector.nombre, 'Viernes')) pass = false;     
        else if(saf && !this.checkTolerancia(sector.tiempo, tiempo_f6, sector.nombre, 'Sábado')) pass = false;  
        else if(dof && !this.checkTolerancia(sector.tiempo, tiempo_f7, sector.nombre, 'Domingo')) pass = false; 

      }
    });

    list.forEach(sector => {

      if(sector.tiempo > 0) {

        var inicio1 = Date.parse("2000/01/01 " + sector.inicio);//MILISEGUNDOS
        var fin1= Date.parse("2000/01/01 " + sector.fin);//MILISEGUNDOS

        list.forEach(sector2 => {

          if(sector.releID != sector2.releID && sector2.tiempo > 0){
  
            var inicio2 = Date.parse("2000/01/01 " + sector2.inicio);//MILISEGUNDOS
            var fin2= Date.parse("2000/01/01 " + sector2.fin);//MILISEGUNDOS

            if(inicio2 >= inicio1 && inicio2 <= fin1 && sector.lu && sector2.lu){
              Swal.fire('¡Error!', 'Rango horario con tope para el día Lunes para los siguientes sectores:<br>' 
              + sector.nombre + ' ' +sector.inicio + ' - ' + sector.fin + '<br>'
              + sector2.nombre + ' ' +sector2.inicio + ' - ' + sector2.fin + '<br>'
              );
              pass = false;
            }else if(inicio2 >= inicio1 && inicio2 <= fin1 && sector.ma && sector2.ma){
              Swal.fire('¡Error!', 'Rango horario con tope para el día Martes para los siguientes sectores:<br>' 
              + sector.nombre + ' ' +sector.inicio + ' - ' + sector.fin + '<br>'
              + sector2.nombre + ' ' +sector2.inicio + ' - ' + sector2.fin + '<br>'
              );
              pass = false;
            }else if(inicio2 >= inicio1 && inicio2 <= fin1 && sector.mi && sector2.mi){
              Swal.fire('¡Error!', 'Rango horario con tope para el día Miércoles para los siguientes sectores:<br>' 
              + sector.nombre + ' ' +sector.inicio + ' - ' + sector.fin + '<br>'
              + sector2.nombre + ' ' +sector2.inicio + ' - ' + sector2.fin + '<br>'
              );
              pass = false;
            }else if(inicio2 >= inicio1 && inicio2 <= fin1 && sector.ju && sector2.ju){
              Swal.fire('¡Error!', 'Rango horario con tope para el día Jueves para los siguientes sectores:<br>' 
              + sector.nombre + ' ' +sector.inicio + ' - ' + sector.fin + '<br>'
              + sector2.nombre + ' ' +sector2.inicio + ' - ' + sector2.fin + '<br>'
              );
              pass = false;
            }else if(inicio2 >= inicio1 && inicio2 <= fin1 && sector.vi && sector2.vi){
              Swal.fire('¡Error!', 'Rango horario con tope para el día Viernes para los siguientes sectores:<br>' 
              + sector.nombre + ' ' +sector.inicio + ' - ' + sector.fin + '<br>'
              + sector2.nombre + ' ' +sector2.inicio + ' - ' + sector2.fin + '<br>'
              );
              pass = false;
            }else if(inicio2 >= inicio1 && inicio2 <= fin1 && sector.sa && sector2.sa){
              Swal.fire('¡Error!', 'Rango horario con tope para el día Sábado para los siguientes sectores:<br>' 
              + sector.nombre + ' ' +sector.inicio + ' - ' + sector.fin + '<br>'
              + sector2.nombre + ' ' +sector2.inicio + ' - ' + sector2.fin + '<br>'
              );
              pass = false;
            }else if(inicio2 >= inicio1 && inicio2 <= fin1 && sector.do && sector2.do){
              Swal.fire('¡Error!', 'Rango horario con tope para el día Domingo para los siguientes sectores:<br>' 
              + sector.nombre + ' ' +sector.inicio + ' - ' + sector.fin + '<br>'
              + sector2.nombre + ' ' +sector2.inicio + ' - ' + sector2.fin + '<br>'
              );
              pass = false;
            }  
          }
  
        });
      }

    });

    return pass;
  }

  guardarPrograma(programa_nombre:string, list:any[]){
    var insert:number = 1;

    list.forEach(item => {

      const PROGRAMA: Programa = {
        id: item.id,
        rele: item.releID,
        inicio: item.inicio,
        tiempo: item.tiempo,
        lu: this.toInt(item.lu).toString(),
        luf: this.toInt(item.luf).toString(),
        f1_tiempo: item.f1_tiempo,
        ma: this.toInt(item.ma).toString(),
        maf: this.toInt(item.maf).toString(),
        f2_tiempo: item.f2_tiempo,
        mi: this.toInt(item.mi).toString(),
        mif: this.toInt(item.mif).toString(),
        f3_tiempo: item.f3_tiempo,
        ju: this.toInt(item.ju).toString(),
        juf: this.toInt(item.juf).toString(),
        f4_tiempo: item.f4_tiempo,
        vi: this.toInt(item.vi).toString(),
        vif: this.toInt(item.vif).toString(),
        f5_tiempo: item.f5_tiempo,
        sa: this.toInt(item.sa).toString(),
        saf: this.toInt(item.saf).toString(),
        f6_tiempo: item.f6_tiempo,
        do: this.toInt(item.do).toString(),
        dof: this.toInt(item.dof).toString(),
        f7_tiempo: item.f7_tiempo,
        fin: item.fin,
        programa: item.programa
      }

      this._riegoAutomaticoService.updatePrograma(PROGRAMA).subscribe(data=> {

        try {
          
          let result = data.data[0].result;
          console.log(data);
          
          if(result == "UPDATE" || result == "INSERT") {            
            insert++;
            if(insert == list.length)  Swal.fire('Exito', '¡Riego Automatico Correctamente!', 'success');
          }
          else {
            Swal.fire('¡Ocurrio un error al editar el Sistema de Riego!', '¡Error!', 'error');
          }

        } catch (error) {
          //console.log(error);
           Swal.fire('¡Error!', data ,'error');
        }

      }, error => {
        console.log(error);
        Swal.fire('¡Error!', error.message,'error');
        //this.sistemaForm.reset();
        //this.hardwareForm.reset();
      });

    });
  }

/**
  programar() {
   
    if(this.sectorID == "") Swal.fire('¡Error!', 'Debe seleccionar Sistema de Riego' ,'error');
    else if(this.horaInicio == "" || this.horaInicio == undefined) Swal.fire('¡Error!', 'Debe seleccionar una hora de inicio' ,'error');
    else if(this.listSectores.length == 0) Swal.fire('¡Error!', 'No hay reles configurados' ,'error');
    else {

      var pass:boolean = true;
      var result = new Date("1999-01-01 " + this.horaInicio);
      console.log(this.horaInicio);
      console.log(result);

      var minutos = result.getHours() * 60 + result.getMinutes();
      var total:number = 0;

      console.log("minutos:" + minutos);
      this.listSectores.forEach(sector => {
        
        let tiempo:number = parseInt(sector.tiempo);
        let tiempo_f1:number = parseInt(sector.f1_tiempo);
        let tiempo_f2:number = parseInt(sector.f2_tiempo);
        let tiempo_f3:number = parseInt(sector.f3_tiempo);
        let tiempo_f4:number = parseInt(sector.f4_tiempo);
        let tiempo_f5:number = parseInt(sector.f5_tiempo);
        let tiempo_f6:number = parseInt(sector.f6_tiempo);
        let tiempo_f7:number = parseInt(sector.f7_tiempo);

        let luf:boolean = this.toBoolean(sector.luf);
        let maf:boolean = this.toBoolean(sector.maf);
        let mif:boolean = this.toBoolean(sector.mif);
        let juf:boolean = this.toBoolean(sector.juf);
        let vif:boolean = this.toBoolean(sector.vif);
        let saf:boolean = this.toBoolean(sector.saf);
        let dof:boolean = this.toBoolean(sector.dof);

        if(luf && !this.checkTolerancia(tiempo, tiempo_f1, sector.nombre, 'Lunes')) pass = false;        
        else if(maf && !this.checkTolerancia(tiempo, tiempo_f2, sector.nombre, 'Martes')) pass = false;   
        else if(mif && !this.checkTolerancia(tiempo, tiempo_f3, sector.nombre, 'Miércoles')) pass = false;        
        else if(juf && !this.checkTolerancia(tiempo, tiempo_f4, sector.nombre, 'Jueves')) pass = false;       
        else if(vif && !this.checkTolerancia(tiempo, tiempo_f5, sector.nombre, 'Viernes')) pass = false;     
        else if(saf && !this.checkTolerancia(tiempo, tiempo_f6, sector.nombre, 'Sábado')) pass = false;  
        else if(dof && !this.checkTolerancia(tiempo, tiempo_f7, sector.nombre, 'Domingo')) pass = false;  

        total+= tiempo;
       
      });

      if(pass && total <= 0) {
        console.log("total:" + total);
        Swal.fire('Atención', '¡El tiempo de riego no puede ejecutarse en 0 minutos!', 'error');
        pass = false;
      }

      minutos += total;

      if(pass && minutos >=1439) {
        console.log("minutos2:" + minutos);
        Swal.fire('Atención', '¡El tiempo de riego no puede superar las 24 horas!', 'error');
        pass = false;
      }

      if(pass){       

        var insert:number = 1;

        this.listSectores.forEach(item => {

          const PROGRAMA: Programa = {
            id: item.programaID,
            rele: item.releID,
            inicio: this.horaInicio,
            tiempo: item.tiempo,
            lu: this.toInt(item.lu).toString(),
            luf: this.toInt(item.luf).toString(),
            f1_tiempo: item.f1_tiempo,
            ma: this.toInt(item.ma).toString(),
            maf: this.toInt(item.maf).toString(),
            f2_tiempo: item.f2_tiempo,
            mi: this.toInt(item.mi).toString(),
            mif: this.toInt(item.mif).toString(),
            f3_tiempo: item.f3_tiempo,
            ju: this.toInt(item.ju).toString(),
            juf: this.toInt(item.juf).toString(),
            f4_tiempo: item.f4_tiempo,
            vi: this.toInt(item.vi).toString(),
            vif: this.toInt(item.vif).toString(),
            f5_tiempo: item.f5_tiempo,
            sa: this.toInt(item.sa).toString(),
            saf: this.toInt(item.saf).toString(),
            f6_tiempo: item.f6_tiempo,
            do: this.toInt(item.do).toString(),
            dof: this.toInt(item.dof).toString(),
            f7_tiempo: item.f7_tiempo 
          }

          this._riegoProgramadoService.updatePrograma(PROGRAMA).subscribe(data=> {

            try {
              
              let result = data.data[0].result;
              
              if(result == "UPDATE") {            
                insert++;
                if(insert == this.listSectores.length)  Swal.fire('Exito', '¡Riego Programado Correctamente!', 'success');
              }
              else {
                Swal.fire('¡Ocurrio un error al editar el Sistema de Riego!', '¡Error!', 'error');
              }
    
            } catch (error) {
              //console.log(error);
               Swal.fire('¡Error!', data ,'error');
            }

          }, error => {
            console.log(error);
            Swal.fire('¡Error!', error.message,'error');
            //this.sistemaForm.reset();
            //this.hardwareForm.reset();
          });

        });
        
      }

    }
  } 

  **/
  checkTolerancia(tiempo:number, tiempo_f:number, nombre:string, dia:string):boolean {

      if(tiempo_f == 0 || isNaN(tiempo_f)) {
        Swal.fire('Error en ' + nombre + ' día ' + dia, 'El tiempo de Fertirriego no puede ser 0' ,'error');
        return false;
      }

      if(tiempo == 0 || isNaN(tiempo)) {
        Swal.fire('Error en ' + nombre + ' día ' + dia, 'El tiempo de Riego no puede ser 0' ,'error');
        return false;
      }

      let per:number = (tiempo_f * 100) / tiempo;
      let percentaje:number = parseFloat(per.toFixed(2));
      if(percentaje > this.tolerancia) {
        Swal.fire('Error en ' + nombre + ' día ' + dia, 'El tiempo de Fertirriego no puede superar el 33% del tiempo de Riego' ,'error');
        return false;
      }
      else return true;
  }
  
  toBoolean (value:any): boolean {
    switch (value) {
      case 'true':
      case '1':
      case 'on':
      case 'yes':
      case 1:
      case true:
        return true;
      default:
        return false;
    }
  }

  toInt(value:any):number {
    switch (value) {
      case 'true':
      case '1':
      case 'on':
      case 'yes':
      case 1:
      case true:
        return 1;
      default:
        return 0;
    }
  }
}
