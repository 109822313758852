import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalsService } from '../libs/globals.service';
import { Programa } from '../models/programa';
import { Sector } from '../models/sector';

@Injectable({
  providedIn: 'root'
})
export class RiegoProgramadoService {

  server = GlobalsService.server
  url = this.server + 'api/riego-programado/';

  constructor(private http: HttpClient) { }
  
  getSectores(sistemaID: String): Observable<any> {
    return this.http.get(this.url + sistemaID);
  }

  getBombas(sistemaID: String): Observable<any> {
    return this.http.get(this.url + "bombas/" + sistemaID);
  }
  
  getProgramas(sistemaID: String): Observable<any> {
    return this.http.get(this.url + "programas/" + sistemaID);
  }

  updatePrograma(programa:Programa): Observable<any> {
    return this.http.post(this.url, programa);
  }
  
  updateSectores(id: String, sector:Sector): Observable<any> {
    return this.http.post(this.url + id, sector);
  }

}
