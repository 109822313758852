<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->   
    
    <div class="row justify-content-md-center">       

        <!-- Area Chart -->
        <div class="col-xl-6" >
            <div class="card shadow mb-4 border-left-primary">
                <!-- Card Header - Dropdown -->
                <div
                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">{{titulo}}</h6>
                    <a [routerLink]="['/proyectos']" class="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm"><i
                        class="fas fa-arrow-left fa-sm text-white-50"></i> volver</a>                    
                </div>
                <!-- Card Body -->
                <div class="card-body">
                    <form [formGroup]="proyectoForm" (ngSubmit)="agregarProyecto()">
                        <div class="form-group">
                            <label>Código</label>
                            <input type="text" class="form-control col-6" formControlName="rut" minlength="11" maxlength="12">
                            <div class="text-danger" *ngIf="proyectoForm.controls['codigo'].hasError('required') && proyectoForm.controls['codigo'].touched">
                                <span>El Código del proyecto <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Nombre</label>
                            <input type="text" class="form-control" formControlName="nombre" minlength="3" maxlength="50">
                            <div class="text-danger" *ngIf="proyectoForm.controls['nombre'].hasError('required') && proyectoForm.controls['nombre'].touched">
                                <span>El nombre del proyecto es <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Cliente</label>
                            <select class="form-control" formControlName="cliente">
                                <option value="">Seleccione un estado</option>
                                <option value="{{cliente.id}}" *ngFor="let cliente of clientesList">{{cliente.nombre}}</option>
                            </select>
                            <div class="text-danger" *ngIf="proyectoForm.controls['cliente'].hasError('required') && proyectoForm.controls['cliente'].touched">
                                <span>Seleccionar un cliente es <strong>obligatorio!</strong></span>
                            </div>                            
                        </div> 
                        <div class="form-group">
                            <label>Estado</label>
                            <select class="form-control" formControlName="estado">
                                <option value="">Seleccione un estado</option>
                                <option value="{{estado.id}}" *ngFor="let estado of listEstados">{{estado.nombre}}</option>
                            </select>
                            <div class="text-danger" *ngIf="proyectoForm.controls['estado'].hasError('required') && proyectoForm.controls['estado'].touched">
                                <span>Seleccionar un estado es <strong>obligatorio!</strong></span>
                            </div>                           
                        </div> 
                        <div class="form-group">
                            <button type="button" class="btn btn-primary" >Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>