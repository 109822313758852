import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalsService } from '../libs/globals.service';
import { Sistema } from '../models/sistema';

@Injectable({
  providedIn: 'root'
})
export class SistemaService {

  server = GlobalsService.server
  url = this.server + 'api/sistemas/';

  constructor(private http: HttpClient) { }
  
  getSistemas(): Observable<any> {
    return this.http.get(this.url);
  }

  getSistema(id: String): Observable<any> {
    return this.http.get(this.url + id);
  }

  addSistema(sistema: Sistema): Observable<any> {
    return this.http.post(this.url, sistema);
  }

  editSistema(id: String, sistema:Sistema): Observable<any> {
    return this.http.post(this.url + id, sistema);
  }

}
