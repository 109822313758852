<div id="wrapper">
    <ul class="accordionSidebar navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar" >

        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center " [routerLink]="['/']">                   
            <img src="assets/img/riegochile.png" alt="riegochile">
        </a>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        
        <app-menu-item>
            
        </app-menu-item>

    </ul>
    <!-- End of Sidebar -->
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
        <app-content></app-content>
    </div>
</div>