<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->

    <div class="row justify-content-md-center">

        <!-- Area Chart -->
        <div class="col-xl-6">
            <div class="card shadow mb-4 border-left-primary">
                <!-- Card Header - Dropdown -->
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">{{titulo}}</h6>
                    <a [routerLink]="['/usuarios']"
                        class="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm"><i
                            class="fas fa-arrow-left fa-sm text-white-50"></i> Volver</a>
                </div>
                <!-- Card Body -->
                <div class="card-body">
                    <form [formGroup]="usuarioForm" >
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">ID Usuario</label>
                            <div class="col-sm-8">
                                <input formControlName="id" type="text" class="form-control form-control-sm">
                                <code *ngIf="usuarioForm.get('id')?.hasError('required') && usuarioForm.controls['id'].touched">El ID Usuario es obligatorio!</code>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Nombre completo</label>
                            <div class="col-sm-8">
                                <input formControlName='nombre' type="text" class="form-control form-control-sm" >
                                <code *ngIf="usuarioForm.get('nombre')?.hasError('required') && usuarioForm.controls['nombre'].touched">El Nombre completo es obligatorio!</code>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Email</label>
                            <div class="col-sm-8">
                                <input formControlName='email' type="email" class="form-control form-control-sm">
                                <code *ngIf="usuarioForm.get('email')?.hasError('pattern') && usuarioForm.controls['email'].touched">Formato inválido!</code>
                                <code *ngIf="usuarioForm.get('email')?.hasError('required') && usuarioForm.controls['email'].touched">El Email es obligatorio!</code>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Teléfono</label>
                            <div class="col-sm-8">
                                <input formControlName='telefono' type="tel" class="form-control form-control-sm">
                                <code *ngIf="usuarioForm.get('telefono')?.hasError('required') && usuarioForm.controls['telefono'].touched">El Teléfono es obligatorio!</code>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Perfil</label>
                            <div class="col-sm-8">
                                <select forControlName='perfil' (change)="changePerfil($event)" class="form-control form-control-sm">
                                    <option value="">Seleccione un Perfil</option>
                                    <option *ngFor="let p of perfiles" [ngValue]="p" >{{p}}</option>
                                </select>
                                <code *ngIf="usuarioForm.get('perfil')?.hasError('required')">El perfil es obligatorio!</code>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Contraseña</label>
                            <div class="col-sm-8">
                                <div class="input-group">
                                    <input formControlName='pass' [type]="fieldTextType ? 'text' : 'password'" class="form-control form-control-sm"
                                        placeholder="Escriba la contraseña">
                                    <div class="input-group-append">
                                        <button class="btn btn-secondary btn-sm" type="button" (click)="toggleFieldTextType()">
                                            <i [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}" class="fa"></i>
                                        </button>
                                        <button class="btn btn-warning btn-sm" type="button">Generar</button>
                                    </div>
                                </div>
                                <code *ngIf="usuarioForm.get('pass')?.hasError('required') && usuarioForm.controls['pass'].touched">La Contraseña es obligatoria!</code>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-secondary" *ngIf="!isAdd"><i class="fas fa-trash fa-sm text-white-50"></i> Eliminar</button>
                    <button type="button" class="btn btn-primary" style="float: right;" [disabled]="usuarioForm.invalid" (click)="agregarUsuario()"><i class="fas fa-save fa-sm text-white-50"></i> Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>