<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->   
    
    <div class="row justify-content-md-center">       

        <!-- Area Chart -->
        <div class="col-xl-6" >
            <div class="card shadow mb-4 border-left-primary">
                <!-- Card Header - Dropdown -->
                <div
                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">{{titulo}}</h6>
                    <a [routerLink]="['/clientes']" class="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm"><i
                        class="fas fa-arrow-left fa-sm text-white-50"></i> volver</a>                    
                </div>
                <!-- Card Body -->
                <div class="card-body">
                    <form [formGroup]="clienteForm" (ngSubmit)="agregarCliente()">
                        <div class="form-group">
                            <label>Rut</label>
                            <input type="text" class="form-control col-6" formControlName="rut" minlength="11" maxlength="12">
                            <div class="text-danger" *ngIf="clienteForm.controls['rut'].hasError('required') && clienteForm.controls['rut'].touched">
                                <span>El rut del cliente <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Nombre</label>
                            <input type="text" class="form-control" formControlName="nombre" minlength="3" maxlength="50">
                            <div class="text-danger" *ngIf="clienteForm.controls['nombre'].hasError('required') && clienteForm.controls['nombre'].touched">
                                <span>El nombre del cliente es <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Teléfono</label>
                            <input type="text" class="form-control col-6" formControlName="telefono" minlength="11" maxlength="12">
                            <div class="text-danger" *ngIf="clienteForm.controls['telefono'].hasError('required') && clienteForm.controls['telefono'].touched">
                                <span>El telefono del cliente <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="email" minlength="3" maxlength="50">
                            <div class="text-danger" *ngIf="clienteForm.controls['email'].hasError('required') && clienteForm.controls['email'].touched">
                                <span>El email del cliente es <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Estado</label>
                            <select class="form-control" formControlName="estado">
                                <option value="">Seleccione un estado</option>
                                <option value="{{estado.id}}" *ngFor="let estado of listEstados">{{estado.nombre}}</option>
                            </select>
                            <div class="text-danger" *ngIf="clienteForm.controls['estado'].hasError('required') && clienteForm.controls['estado'].touched">
                                <span>Seleccionar un estado es <strong>obligatorio!</strong></span>
                            </div>
                            <!--div class="input-group">
                                <input type="text" class="form-control" placeholder="" disabled>
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="button">
                                        <i class="fas fa-search fa-sm"></i>
                                    </button>
                                </div>
                            </div-->
                        </div>    
                        <div class="form-group">
                            <button type="button" class="btn btn-primary" >Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>