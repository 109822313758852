import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Sistema } from 'src/app/models/sistema';
import { ClienteService } from 'src/app/services/cliente.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { SistemaService } from 'src/app/services/sistema.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sistemas-add',
  templateUrl: './sistemas-add.component.html',
  styleUrls: ['./sistemas-add.component.css']
})
export class SistemasAddComponent implements OnInit {

  sistemaForm: FormGroup;
  hardwareForm: FormGroup;
  listRelee: any[] = [];
  listClientes: any[] = [];
  listProyectos: any[] = [];
  id: string | null;
  title: string = "Nuevo Sistema de Riego";  
  editando: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private _clienteService: ClienteService,
    private _proyectoService: ProyectoService, private aRouter: ActivatedRoute, private _sistemaService: SistemaService) {

    this.sistemaForm = this.fb.group({
      nombre: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      cliente: ['', Validators.required],
      proyecto: ['', Validators.required]
    });

    this.hardwareForm = this.fb.group({
      controladorID: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      controladorTipo: ['', Validators.required],
      comunicacionTipo: ['', Validators.required],
      modo: ['', Validators.required]
    });

    for (var i in this.sistemaForm.controls) {
      this.sistemaForm.controls[i].updateValueAndValidity();
    }

    for (var i in this.hardwareForm.controls) {
      this.hardwareForm.controls[i].updateValueAndValidity();
    }

    console.log("constructor");
    this.id = (this.aRouter.snapshot.paramMap.get('id') == "" ? null : this.aRouter.snapshot.paramMap.get('id'));

  }

  ngOnInit(): void {
   
    this.poblarRelee();
    this.obtenerClientes();
    this.obtenerProyectos();
    
    if (this.id != null) {
      this.editando = true;
      this.title = "Editando Sistema de Riego";
      this._sistemaService.getSistema(this.id).subscribe(data => {
        
        let sistema = data.data[0];
        console.log(sistema);

        this.sistemaForm.setValue({
          nombre: sistema.nombre,
          cliente: sistema.clienteID,
          proyecto: sistema.proyectoID
        })

        this.hardwareForm.setValue({
          controladorID: sistema.controladorID,
          controladorTipo: sistema.controladorTipo,
          comunicacionTipo: sistema.controladorTipo,
          modo: sistema.modo
        })
        
      }, error => {
        console.log(error);
      });
    }
  }

  poblarRelee() {
    this.listRelee = [
      { 'orden': 1, 'nombre': 'Relee N° 1', 'tipo': 'vacio' },
      { 'orden': 2, 'nombre': 'Relee N° 2', 'tipo': 'vacio' },
      { 'orden': 3, 'nombre': 'Relee N° 3', 'tipo': 'vacio' },
      { 'orden': 4, 'nombre': 'Relee N° 4', 'tipo': 'vacio' },
      { 'orden': 5, 'nombre': 'Relee N° 5', 'tipo': 'vacio' },
      { 'orden': 6, 'nombre': 'Relee N° 6', 'tipo': 'vacio' },
      { 'orden': 7, 'nombre': 'Relee N° 7', 'tipo': 'vacio' },
      { 'orden': 8, 'nombre': 'Relee N° 8', 'tipo': 'vacio' },
      { 'orden': 9, 'nombre': 'Relee N° 9', 'tipo': 'vacio' },
      { 'orden': 10, 'nombre': 'Relee N° 10', 'tipo': 'vacio' }
    ]
  }

  obtenerClientes() {
    this._clienteService.getClientes().subscribe(data => {
      this.listClientes = data.data;
    }, error => {
      console.log(error);
    });
  }

  obtenerProyectos() {
    this._proyectoService.getProyectos().subscribe(data => {
      this.listProyectos = data.data;
    }, error => {
      console.log(error);
    });
  }

  agregarSistema() {

    const SISTEMA: Sistema = {
      nombre: this.sistemaForm.get('nombre')?.value,
      clienteID: this.sistemaForm.get('cliente')?.value,
      proyectoID: this.sistemaForm.get('proyecto')?.value,
      controladorID: this.hardwareForm.get('controladorID')?.value,
      controladorTipo: this.hardwareForm.get('controladorTipo')?.value,
      comunicacionTipo: this.hardwareForm.get('comunicacionTipo')?.value,
      modo: this.hardwareForm.get('modo')?.value
    }

    if (this.id !== null) {
      //EDITAR
      this._sistemaService.editSistema(this.id, SISTEMA).subscribe(data => {

        try {
          let result = data.data[0].result;

          if(result == "UPDATE") {            
            Swal.fire('¡Sistema de Riego Editado Correctamente!', '¡Ahora puedes configurar Relee\'s!', 'success');
          }
          else {
            Swal.fire('¡Ocurrio un error al editar el Sistema de Riego!', '¡Error!', 'error');
          }

        } catch (error) {
           Swal.fire('¡Error!', data ,'error');
        }
       
        //this.router.navigate(['/'])

      }, error => {
        console.log(error);
        Swal.fire('¡Error!', error.message,'error');
        //this.sistemaForm.reset();
        //this.hardwareForm.reset();
      });

    } else {
      //ADD
      this._sistemaService.addSistema(SISTEMA).subscribe(data => {

        try {
          let result = data.data[0].result;

          if(result == "INSERT") {
            let sistemaID = data.data[0].sistemaID;
            this.id = sistemaID;
            this.editando = true;
            this.title = "Editando Sistema de Riego";
            Swal.fire('¡Sistema de Riego Creado Correctamente!', '¡Ahora puedes configurar Relee\'s!', 'success');
          }
          else if(result == "EXISTE") {
            Swal.fire('¡ID Controlador ' + SISTEMA.controladorID + ' Duplicado!', '¡Intente con otro ID!', 'warning');
          }
          else {
            Swal.fire('¡Ocurrio un error al crear el Sistema de Riego!', '¡Error!', 'error');
          }

        } catch (error) {
           Swal.fire('¡Error!', data ,'error');
        }
       
        //this.router.navigate(['/'])

      }, error => {
        console.log(error);
        Swal.fire('¡Error!', error.message,'error');
        //this.sistemaForm.reset();
        //this.hardwareForm.reset();
      });
    }
  }

  eliminarRelee(id:any) {
    

  }
}
