 <!-- Begin Page Content -->
 <div class="container-fluid">
     

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Riego Manual</h1>
        <a (click)="guardarManual()" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                class="fas fa-download fa-sm text-white-50"></i> Guardar</a>
    </div>
      <!-- Content Row -->

    <div class="row">
        <div class="col-12 col-lg-3">
            <div class="form-group">
                <select class="form-control" #sistema (change)="sistemaOnChange(sistema.value)">
                    <option value="">Seleccione un Sistema de Riego</option>
                    <option value="{{s.id}}" *ngFor="let s of listSistemas">{{s.nombre}}</option>
                </select>                                                                            
            </div>            
        </div>        
    </div>

    <div class="row" *ngIf="sistema.value != ''">
        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-4 col-md-6 mb-4">
            <div class="card border-left-success shadow h-100 py-2">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col-6">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1 text-center">
                                MODO</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800 text-center">MANUAL</div>
                            <div class="form-check form-switch text-center">
                                <input class="form-check-input" type="checkbox" [checked]="manual" style="width: 3rem;" (change)="changeCheck()" >
                            </div>
                        </div>                       
                        <div class="col-6 no-gutters align-items-center">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1 text-center">
                                MODO</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800 text-center">AUTO</div>
                            <div class="form-check form-switch text-center">
                                <input class="form-check-input" type="checkbox" [checked]="!manual" style="width: 3rem;" (change)="changeCheck()"  >
                            </div>
                        </div>                        
                    </div>
                    <div class="row no-gutters align-items-center mt-3"><div class="text-xs font-weight-bold text-success text-uppercase mb-1 text-center">
                        <strong>última comunicación:</strong> {{comunicacion | date:'medium'}}</div></div>
                </div>
            </div>
        </div>       
    </div>
    
    <div class="row">
        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-4 col-md-6 mb-4" *ngFor="let sector of listSectores">
            <div class="card border-left-success shadow h-100 py-2">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                RELÉ N°{{sector.numero}}</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{sector.nombre}}</div>
                        </div>
                        <div class="col-auto">
                            <div class="form-check form-switch text-center">
                                <input class="form-check-input" type="checkbox" style="width: 3rem;" [checked]="sector.estado" (change)="sectorOnChange(sector)" >
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>       
    </div>
    <!-- Content Row -->
 </div>
