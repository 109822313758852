import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-proyectos-add',
  templateUrl: './proyectos-add.component.html',
  styleUrls: ['./proyectos-add.component.css']
})
export class ProyectosAddComponent implements OnInit {

  titulo = 'Nuevo Proyecto';
  proyectoForm: FormGroup;
  clientesList: any[] = [];
  listEstados: any[] = [
    {id:'1', nombre:'Habilitado'},
    {id:'0', nombre:'Deshabiltado'}
  ];

  constructor(private fb:FormBuilder) { 
    this.proyectoForm = this.fb.group({
      codigo: ['',[Validators.required]],
      nombre: ['',[Validators.required]],
      cliente: ['', [Validators.required]],
      estado: ['', [Validators.required]]
    });
  }

  agregarProyecto():void {

  }

  ngOnInit(): void {
  }
  

}
