import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalsService } from '../libs/globals.service';

@Injectable({
  providedIn: 'root'
})
export class ProyectoService {

  server = GlobalsService.server
  url = this.server + 'api/proyectos';
  constructor(private http: HttpClient) { }

  getProyectos(): Observable<any> {
    return this.http.get(this.url);
  }
}
