import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProyectoService } from 'src/app/services/proyecto.service';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.css']
})
export class ProyectosComponent implements OnInit {

  listProyectos: any[] = [];

  constructor(private fb:FormBuilder, private _proyectoService: ProyectoService) {
   
   }

  ngOnInit(): void {
    document.getElementById('accordionSidebar')?.classList.remove("toggled");

    this.obtenerProyectos();
  
  }

  obtenerProyectos() {
    this._proyectoService.getProyectos().subscribe(data => {
      this.listProyectos = data.data;
    }, error => {
      console.log(error);
    });
  }

  eliminarProyecto(id:any){
    
  }

}
