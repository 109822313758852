import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  listClientes: any[] = [];

  constructor(private _clienteService: ClienteService) { }

  ngOnInit(): void {
    document.getElementById('accordionSidebar')?.classList.remove("toggled");

    this.obtenerClientes();
  }

  obtenerClientes() {
    this._clienteService.getClientes().subscribe(data => {
      this.listClientes = data.data;
    }, error => {
      console.log(error);
    });
  }

  eliminarCliente(id:any) {
    
  }


}
