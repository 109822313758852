<!-- Begin Page Content -->
<div class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-xl-4">
            <h1 class="h3 mb-10 text-gray-800">{{title}}</h1>
        </div>
        <div class="col-xl-4">
            <button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right"  (click)="agregarSistema()"
            [disabled]="sistemaForm.invalid || hardwareForm.invalid" type="submit"><i class="fas fa-save fa-sm text-white-50"></i> Guardar</button>
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-xl-4">
            <div class="card shadow mb-4 border-left-primary">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">Sistema de Riego</h6>
                </div>
                <div class="card-body">
                    <form [formGroup]="sistemaForm" (ngSubmit)="agregarSistema()">
                        <div class="form-group">
                            <label>Nombre Sistema de Riego</label>
                            <input type="text" class="form-control" formControlName="nombre" minlength="3" maxlength="50">
                            <div class="text-danger" *ngIf="sistemaForm.controls['nombre'].hasError('required') && sistemaForm.controls['nombre'].touched">
                                <span>El nombre del sistema es <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Cliente</label>
                            <select class="form-control" formControlName="cliente">
                                <option value="">Seleccione un Cliente</option>
                                <option value="{{cliente.id}}" *ngFor="let cliente of listClientes">{{cliente.nombre}}</option>
                            </select>
                            <div class="text-danger" *ngIf="sistemaForm.controls['cliente'].hasError('required') && sistemaForm.controls['cliente'].touched">
                                <span>Seleccionar un cliente es <strong>obligatorio!</strong></span>
                            </div>
                            <!--div class="input-group">
                                <input type="text" class="form-control" placeholder="" disabled>
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="button">
                                        <i class="fas fa-search fa-sm"></i>
                                    </button>
                                </div>
                            </div-->
                        </div>
                        <div class="form-group">
                            <label>Proyecto</label>
                            <select class="form-control" formControlName="proyecto">
                                <option value="">Seleccione un Proyecto</option>
                                <option value="{{proyecto.id}}" *ngFor="let proyecto of listProyectos">{{proyecto.nombre}}</option>
                            </select>
                            <div class="text-danger" *ngIf="sistemaForm.controls['proyecto'].hasError('required') && sistemaForm.controls['proyecto'].touched">
                                <span>Seleccionar un proyecto es <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="card shadow mb-4 border-left-success">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">Hardware</h6>
                </div>
                <div class="card-body">
                    <form [formGroup]="hardwareForm" (ngSubmit)="agregarSistema()">
                        <div class="form-group">
                            <label>ID Controlador</label>
                            <input [attr.disabled]="editando? true : null" type="text" class="form-control" formControlName="controladorID" maxlength="10">
                            <div class="text-danger" *ngIf="hardwareForm.controls['controladorID'].hasError('required') && hardwareForm.controls['controladorID'].touched">
                                <span>Ingresar un ID Controlador es <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Tipo Controlador</label>
                            <select class="form-control" formControlName="controladorTipo">
                                <option value="">Seleccione un tipo</option>
                                <option value="1">SP32</option>
                                <option value="2">Arduino Uno</option>
                            </select>
                            <div class="text-danger" *ngIf="hardwareForm.controls['controladorTipo'].hasError('required') && hardwareForm.controls['controladorTipo'].touched">
                                <span>Seleccionar un Tipo Controlador es <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Tipo de Comunicación</label>
                            <select class="form-control" formControlName="comunicacionTipo">
                                <option value="">Seleccione un tipo</option>
                                <option value="1">Web Socket</option>
                                <option value="2">Api</option>
                            </select>
                            <div class="text-danger" *ngIf="hardwareForm.controls['comunicacionTipo'].hasError('required') && hardwareForm.controls['comunicacionTipo'].touched">
                                <span>Seleccionar un Tipo de Comunicación es <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Modo de Operación por Defecto</label>
                            <select class="form-control" formControlName="modo">
                                <option value="">Seleccione un modo</option>
                                <option value="MAN">Manual</option>
                                <option value="AUTO">Automático</option>
                            </select>
                            <div class="text-danger" *ngIf="hardwareForm.controls['modo'].hasError('required') && hardwareForm.controls['modo'].touched">
                                <span>Seleccionar un Modo de Operación por Defecto es <strong>obligatorio!</strong></span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-xl-4">
            <h1 class="h3 mb-10 text-gray-800">Relee's</h1>
        </div>
        <div class="col-xl-4">

        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-xl-8">
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">Todos los sistemas de riego</h6>
                </div>
                <div class="card-body">

                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">N°</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Caudal Nominal</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let relee of listRelee">
                                    <td>{{relee.nombre}}</td>
                                    <td>{{relee.controladorID}}</td>
                                    <td>{{relee.controladorID}}</td>
                                    <td>{{relee.registro_fecha}}</td>
                                    <td>
                                        <i [routerLink]="['/relee-add', relee.id]" class="fas fa-edit text-primary"></i>
                                        <i (click)="eliminarRelee(relee.id)" class="fas fa-trash text-secondary"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!--div class="row">
                <div class="col-xl-4" *ngFor="let relee of listRelee">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">{{relee.nombre}}</h6>
                        </div>
                        <div class="card-body">

                        </div>
                    </div>
                </div>                
            </div-->
        </div>
    </div>
</div>