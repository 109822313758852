import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  sidebarToggle(){

    if(document.getElementById('accordionSidebar')?.classList.contains("toggled")) document.getElementById('accordionSidebar')?.classList.remove("toggled");
    else document.getElementById('accordionSidebar')?.classList.add("toggled");

  }

}
