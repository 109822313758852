import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  listUsuarios: any[] = [];

  constructor(private _usuarioService: UsuarioService) { }

  ngOnInit(): void {
    document.getElementById('accordionSidebar')?.classList.remove("toggled");

    this.obtenerUsuarios();
  }

  obtenerUsuarios(){
    this._usuarioService.getUsuarios().subscribe(data => {
      this.listUsuarios = data.data;
    }, error => {
      console.log(error);
    })
  }

  eliminarUsuario(id:any){
    
  }

}
