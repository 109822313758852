<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Riego Programado</h1>
    </div>
    <div class="row">
        <div class="col-12 col-lg-3">
            <div class="form-group">
                <select class="form-control" #sistema (change)="sistemaOnChange(sistema.value)">
                    <option value="">Seleccione un Sistema de Riego</option>
                    <option value="{{sistema.id}}" *ngFor="let sistema of listSistemas">{{sistema.nombre}}</option>
                </select>                                                                
            </div>            
        </div>        
    </div>
    <div class="row">
        <div class="col-xl-4 col-md-6 mb-4" *ngFor="let bomba of listBombas">
            <div class="card border-left-success shadow h-100 py-2">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                RELÉ N°{{bomba.numero}}</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{bomba.nombre}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>       
    </div>
    <div class="row mt-2" *ngIf="sistema.value != ''">
        <div class="col-12">
            <div class="card shadow mb-4">
                <div class="card-header py-3 bg-primary">
                    <h6 class="m-0 font-weight-bold text-white">Programación Semanal</h6>
                </div>
                <div class="card-body"> 
                    <div class="row">                        
                        <div class="col-12 col-lg-12 mt-2">
                            <button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right" data-bs-toggle="modal" data-bs-target="#modalProgramar">
                                <i class="fas fa-save fa-sm text-white-50"></i> Programar</button>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Día</th>
                                        <th scope="col">Inicio</th>
                                        <th scope="col">Fin</th>
                                        <th scope="col">Rele</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let programa of listProgramas">
                                        <td><strong>{{programa.dia}}</strong></td>
                                        <td><strong>{{programa.inicio}}</strong></td>
                                        <td><strong>{{programa.fin}}</strong></td>
                                        <td>{{programa.rele}}</td>
                                        <td>{{programa.tipo}}</td>
                                        <td><strong>{{programa.sector}}</strong></td>    
                                        <td><span class="badge badge-success">Ejecutado</span>
                                        </td>                                      
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>      
</div>
<div id="modalProgramar" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Programación</h4>
          <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body">
          
            <form [formGroup]="programaForm" >
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Sector</label>
                    <div class="col-sm-8">
                        <select class="form-control" formControlName="sectorID">
                            <option value="">Seleccione un Sector</option>
                            <option value="{{sector.id}}" *ngFor="let sector of listSectores">{{sector.nombre}}</option>
                        </select>
                        <code *ngIf="programaForm.get('sectorID')?.hasError('required') && programaForm.controls['sectorID'].touched">Seleccione un sector!</code>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Día</label>
                    <div class="col-sm-8">
                        <select class="form-control" formControlName="diaID">
                            <option value="">Seleccione un día</option>
                            <option value="{{dia.diaID}}" *ngFor="let dia of listDias">{{dia.nombre}}</option>
                        </select>
                        <code *ngIf="programaForm.get('diaID')?.hasError('required') && programaForm.controls['diaID'].touched">Seleccione un día!</code>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Inicio / Fin</label>
                    <div class="col-sm-4">
                        <input type="time" class="form-control" formControlName="inicio" >
                        <code *ngIf="programaForm.get('inicio')?.hasError('required') && programaForm.controls['inicio'].touched">Selecciona una hora de inicio!</code>
                    </div>
                    <div class="col-sm-4">
                        <input type="time" class="form-control" formControlName="fin" >
                        <code *ngIf="programaForm.get('fin')?.hasError('required') && programaForm.controls['fin'].touched">Selecciona una hora de fin!</code>
                    </div>
                </div>                
            </form>

            <form [formGroup]="programaForm" >
                
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Fertirriego</label>
                    <div class="col-sm-4">
                        <div class="form-check form-switch">
                            <input class="form-check-input mt-2" style="margin-left: -1em;" type="checkbox" id="checkF">
                        </div>
                    </div>                  
                </div>      

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Inicio / Fin</label>
                    <div class="col-sm-4">
                        <input type="time" class="form-control" formControlName="inicio" >
                        <code *ngIf="programaForm.get('inicio')?.hasError('required') && programaForm.controls['inicio'].touched">Selecciona una hora de inicio!</code>
                    </div>
                    <div class="col-sm-4">
                        <input type="time" class="form-control" formControlName="fin" >
                        <code *ngIf="programaForm.get('fin')?.hasError('required') && programaForm.controls['fin'].touched">Selecciona una hora de fin!</code>
                    </div>
                </div>                
            </form>

        </div>        
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="guardar()">Guardar</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
        </div>                
      </div>
    </div>
</div>