import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './layout/nav-bar/nav-bar.component';
import { MenuItemComponent } from './layout/menu-item/menu-item.component';
import { ContentComponent } from './layout/content/content.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RiegoManualComponent } from './components/riego-manual/riego-manual.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { HttpClientModule} from '@angular/common/http';
import { ClientesAddComponent } from './components/clientes-add/clientes-add.component';
import { SistemasComponent } from './components/sistemas/sistemas.component';
import { SistemasAddComponent } from './components/sistemas-add/sistemas-add.component';
import { ProyectosComponent } from './components/proyectos/proyectos.component';
import { ProyectosAddComponent } from './components/proyectos-add/proyectos-add.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { UsuariosAddComponent } from './components/usuarios-add/usuarios-add.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DepuracionComponent } from './components/depuracion/depuracion.component';
import { RiegoProgramadoComponent } from './components/riego-programado/riego-programado.component';
import { RiegoAutomaticoComponent } from './components/riego-automatico/riego-automatico.component';
import { RiegoSecuencialComponent } from './components/riego-secuencial/riego-secuencial.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    MenuItemComponent,
    ContentComponent,
    DashboardComponent,
    RiegoManualComponent,
    ClientesComponent,
    ClientesAddComponent,
    SistemasComponent,
    SistemasAddComponent,
    ProyectosComponent,
    ProyectosAddComponent,
    UsuariosComponent,
    UsuariosAddComponent,
    DepuracionComponent,
    RiegoProgramadoComponent,
    RiegoAutomaticoComponent,
    RiegoSecuencialComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
