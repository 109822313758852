import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class GlobalsService {

  //DESARROLLO  
  //public static server: string = 'http://localhost:4999/';
  //public static socket_server: string = 'ws://localhost:5000/';
  
  //PRODUCCION
  //public static server: string = 'https://codeai.cl/api_riegochile/';
  //public static socket_server: string = 'ws://codeai.cl/api_riegochile:5000/';

  //PRODUCCION
  public static server: string = 'https://riegoapi.c4i-udec.cl/';
  public static socket_server: string = 'wss://riegows.c4i-udec.cl';
}
